<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Event Category</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              dense
              clearable
              name="title"
              label="Name"
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              id="title"
              @input="nameError = []"
              :error-messages="nameError"
              @change="onChangeName()"
              placeholder="e.g. Name"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { CategoryService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      data: {},
      events: [],
      nameError: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getCategoryById(this.$route.params.id);
    }
  },
  methods: {
    async getCategoryById(id) {
      this.data = await CategoryService.getById(id);
    },
    async save() {
      if (this.$refs.form.validate() && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Category?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              const response = await CategoryService.update(this.data);
              if (response) {
                this.$next({ name: 'admin.event-category' });
              }
            } else {
              const response = await CategoryService.save(this.data);
              if (response) {
                this.$next({ name: 'admin.event-category' });
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.event-category' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await CategoryService.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
