var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('div',[_vm._v(_vm._s(_vm.text)+" Performer")])]),_c('v-card-text',{staticClass:"pb-4 pb-sm-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","name":"Name","label":"Name","clearable":"","rules":[v => !!v || 'Name is required', v => (v && v.length < 50) || 'Name must less than 50 character'],"id":"Name","error-messages":_vm.nameError,"placeholder":"Performer Name"},on:{"input":function($event){_vm.nameError = []},"change":function($event){return _vm.onChangeName()}},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Add links ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.data.links),function(link,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"outlined":"","dense":"","item-text":"text","clearable":"","item-value":"value","items":_vm.linkTypes,"label":"Link type"},model:{value:(link.linkType),callback:function ($$v) {_vm.$set(link, "linkType", $$v)},expression:"link.linkType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"Link URL","placeholder":"Social links url","rules":[
                  v =>
                    !v ||
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                      v
                    ) ||
                    'Invalid URL',
                ]},model:{value:(link.url),callback:function ($$v) {_vm.$set(link, "url", $$v)},expression:"link.url"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[(index === 0 && _vm.data.links.length === 1)?_c('v-icon',{staticClass:"cursor-pointer pt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.refreshLinks(index)}}},[_vm._v("mdi-refresh")]):_vm._e(),(index >= 1)?_c('v-icon',{staticClass:"cursor-pointer pt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.removeLink(index)}}},[_vm._v("mdi-delete-outline")]):_vm._e()],1)],1)}),1),(_vm.data.links.length < 3)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addLink()}}},[_vm._v("mdi-plus-circle-outline")]),_c('b',{staticClass:"cursor-pointer align-middle",staticStyle:{"color":"#d93d34"},on:{"click":function($event){return _vm.addLink()}}},[_vm._v("Add link")])],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"width":"100","outlined":"","color":"primary"},on:{"click":function($event){return _vm.discard()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"width":"100","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }