<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Event Series</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="name"
              clearable
              label="Name"
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              id="name"
              @input="nameError = []"
              :error-messages="nameError"
              @change="onChangeName()"
              placeholder="e.g. Name"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="data.organiser"
              clearable
              :items="organisers"
              :filter="customFilter"
              item-text="name"
              item-value="_id"
              label="Promoter"
              :rules="[v => !!v || 'Promoter is required']"
              dense
              outlined
            >
            <template v-slot:selection="data">
            <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
            </template>
            <template v-slot:item="data">
              <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
            </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="data.eventType"
              clearable
              :items="eventTypes"
              item-text="name"
              item-value="_id"
              label="Type"
              :rules="[v => !!v || 'Type is required']"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="data.genre"
              clearable
              :items="genres"
              item-text="name"
              item-value="_id"
              label="Category"
              @change="getSubCategory()"
              :rules="[v => !!v || 'Category is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="data.subCategory"
              clearable
              :items="subcategories"
              item-text="name"
              item-value="_id"
              label="Sub Category"
              :rules="[v => !!v || 'Sub Category is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"> Add links </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row v-for="(link, index) in data.links" :key="index">
              <v-col cols="12" sm="4">
                <v-select
                  clearable
                  outlined
                  dense
                  item-text="text"
                  item-value="value"
                  :items="linkTypes"
                  v-model="link.linkType"
                  label="Link type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  outlined
                  clearable
                  dense
                  v-model="link.url"
                  label="Link URL"
                  placeholder="Social links url"
                  :rules="[
                    v =>
                      !v ||
                      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                        v
                      ) ||
                      'Invalid URL',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1">
                <v-icon
                  v-if="index === 0 && data.links.length === 1"
                  class="cursor-pointer pt-2"
                  color="primary"
                  @click="refreshLinks(index)"
                  >mdi-refresh</v-icon
                >
                <v-icon v-if="index >= 1" class="cursor-pointer pt-2" color="primary" @click="removeLink(index)"
                  >mdi-delete-outline</v-icon
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-icon class="cursor-pointer" color="primary" @click="addLink()">mdi-plus-circle-outline</v-icon>
            <b class="cursor-pointer align-middle" style="color: #d93d34" @click="addLink()">Add link</b>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { EventSeries, EventTypeService, CategoryService, SubCategoryService, PromoterService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      linkTypes: [
        { text: 'Youtube', value: 'youtube' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'Twitter', value: 'twitter' },
      ],
      data: {
        // name: '',
        // organiser: '',
        // eventType: '',
        // genre: '',
        // subCategory: '',
        links: [{ linkType: '', url: '' }],
      },
      eventTypes: [],
      genres: [],
      subcategories: [],
      organisers: [],
      nameError: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getEventSeriesById(this.$route.params.id);
    }
    const eventData = await EventTypeService.get({ limit: 'all' });
    const genreData = await CategoryService.get({ limit: 'all' });
    const organiserData = await PromoterService.get({ limit: 'all' });
    this.eventTypes = eventData.eventTypes;
    this.genres = genreData.genres;
    this.organisers = organiserData.organisers;
  },
  methods: {
    async getEventSeriesById(id) {
      this.data = await EventSeries.getById(id);
      this.data.genre = this.data.genre._id;
      if (this.data && this.data.links && this.data.links.length === 0) {
        this.data.links = [{ linkType: '', url: '' }];
      }
      this.getSubCategory();
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async getSubCategory() {
      const subCategoryData = await SubCategoryService.get({ genre: this.data.genre, limit: 'all' });
      this.subcategories = subCategoryData.subCategories;
    },
    async save() {
      if (this.$refs.form.validate() && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Event Series?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              const response = await EventSeries.update(this.data);
              if (response) {
                this.$next({ name: 'admin.event-series' });
              }
            } else {
              const response = await EventSeries.save(this.data);
              if (response) {
                this.$next({ name: 'admin.event-series' });
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.event-series' });
    },
    addLink() {
      this.data.links.push({ linkType: '', url: '' });
    },
    refreshLinks() {
      this.data.links = [{ linkType: '', url: '' }];
    },
    removeLink(index) {
      this.data.links.splice(index, 1);
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await EventSeries.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
.http-https-container {
  background-color: var(--v-primary-base) !important;
  color: #fff;
  min-height: 40px;
  border-radius: 5px;
}
</style>
