<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Custom Widget</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="title"
              clearable
              label="Name"
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              id="title"
              placeholder="e.g. Name"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              clearable
              type="email"
              name="email"
              label="Email"
              v-model="data.email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              id="email"
              :error-messages="emailError"
              @input="emailError = []"
              @change="onChangeEmail()"
              placeholder="e.g. Email of User"
            />
          </v-col>
        </v-row>

        <!-- <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="data.event"
              :items="events"
              item-text="title"
              clearable
              item-value="_id"
              @input="nameError = []"
              @change="onChangeName()"
              label="Events"
              :rules="[v => !!v || 'Events is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col> -->
        <!-- <v-col cols="12" sm="6">
            <v-autocomplete
              :items="organisers"
              label="Promoter"
              outlined
              dense
              clearable
              item-text="name"
              item-value="_id"
              :filter="customFilter"
              v-model="data.organiser"
              :rules="[v => !!v || 'Organiser is required']"
              placeholder="e.g Select Promoter"
            >
              <template v-slot:selection="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
              <template v-slot:item="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
            </v-autocomplete>
          </v-col> -->
        <v-row class="position-relative">
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="data.organiser"
              clearable
              :disabled="data._id ? true : false"
              :items="organisers"
              :filter="customFilter"
              @change="getEvents()"
              item-text="name"
              item-value="_id"
              label="Promoter"
              :rules="[promoterRequiredRule]"
              dense
              outlined
            >
              <template v-slot:selection="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
              <template v-slot:item="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" class="hidden-sm-and-up text-center">
            <span class="primary--text text-bold">Or</span>
          </v-col>

          <span class="primary--text text-bold absolute-center-x hidden-xs-only">Or</span>

          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="data.venue"
              clearable
              :items="venues"
              item-text="name"
              item-value="_id"
              :rules="[venueRequiredRule]"
              label="Venue"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              dense
              type="text"
              name="url"
              label="URL"
              v-model="data.siteURL"
              :rules="[
                v => !!v || 'URL is required',
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              id="url"
              placeholder="e.g. URL"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { PromoterEventService, CustomWidgetService, PromoterService, VenueService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      data: {},
      genres: [],
      emailError: [],
      events: [],
      organisers: [],
      venues: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getById(this.$route.params.id);
    }
    // this.getEvents()
    this.getOrganisers();
    this.venues = await VenueService.getAll({ status: 'active', limit: 'max' });
  },
  computed: {
    venueRequiredRule() {
      return !(this.data.organiser && this.data.venue) || 'You can not select promoter or venue together';
    },
    promoterRequiredRule() {
      return !(!this.data.organiser && !this.data.venue) || 'Promoter is required';
    },
  },
  methods: {
    async getById(id) {
      this.data = await CustomWidgetService.getById(id);
      if (this.data && this.data.events && this.data.events.length > 0) {
        this.data.event = this.data.events[0];
      }
    },
    async getOrganisers() {
      const promoterdata = await PromoterService.get({ limit: 'all' });
      if (promoterdata) {
        this.organisers = promoterdata.organisers;
      }
    },
    // async getEvents() {
    //   const eventData = await PromoterEventService.getAll({ isUpcomig: true, status: 'active'});
    //   this.events = eventData;
    // },
    customFilter(item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`;
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    async save() {
      if (this.$refs.form.validate() && !this.emailError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Custom Widget?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              // this.data.events = [this.data.event]
              const response = await CustomWidgetService.update(this.data);
              if (response) {
                this.$next({ name: 'admin.custom-widget' });
              }
            } else {
              const response = await CustomWidgetService.save(this.data);
              if (response) {
                this.$next({ name: 'admin.custom-widget' });
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.custom-widget' });
    },
    async onChangeEmail() {
      this.emailError = [];
      if (this.data.email) {
        const response = await CustomWidgetService.checkDuplicate(this.data.email);
        if (response) {
          if (response._id != this.data._id) {
            this.emailError = ['Email already exists'];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
