<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Promoter</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="name"
              label="Name"
              clearable
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              id="name"
              :error-messages="nameError"
              @input="nameError = []"
              @change="onChangeName()"
              placeholder="e.g. Name"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :key="key"
              outlined
              dense
              name="slug"
              label="Slug"
              v-model="data.slug"
              clearable
              @input="slugError = []"
              @change="onChangeSlug()"
              :error-messages="slugError"
              :rules="[v => !!v || 'Slug is required']"
              id="slug"
              placeholder="e.g. Slug"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="data.type"
              :items="types"
              clearable
              :item-text="'label'"
              :item-value="'value'"
              label="Type"
              :rules="[v => !!v || 'Type is required']"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="data.venue"
              clearable
              :items="venues"
              item-text="name"
              item-value="_id"
              label="Venue"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              :disabled="isEmailDisabled"
              clearable
              type="email"
              name="email"
              label="Email"
              v-model="data.user.email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              id="email"
              :error-messages="emailError"
              @input="emailError = []"
              @change="onChangeEmail()"
              placeholder="e.g. Email of User"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-switch class="pa-0 ma-0" v-model="data.isStatusVetting" :label="`Is Event Approval Needed ?`"></v-switch>
          </v-col>
          <v-col cols="12" sm="3">
            <v-switch
              class="pa-0 ma-0"
              v-model="data.isAbleToRefundTicket"
              :label="`Is Able to Refund Ticket ?`"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { PromoterService, VenueService, AuthService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      isEmailDisabled: false,
      data: {
        isStatusVetting: true,
        user: {},
      },
      emailError: [],
      slugError: [],
      nameError: [],
      venues: [],
      types: [
        {
          label: 'Promoter',
          value: 'promoter',
        },
        {
          label: 'Venue',
          value: 'venue',
        },
        {
          label: 'Artist',
          value: 'artist',
        },
      ],
      key: 0,
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.isEmailDisabled = true;
      this.getOrganiserById(this.$route.params.id);
    }
    this.venues = await VenueService.getAll({ status: 'active', limit: 'max'});
  },
  methods: {
    async getOrganiserById(id) {
      this.data = await PromoterService.getById(id);
      this.data.user = this.data.user ? this.data.user : {};
    },
    async save() {
      if (this.$refs.form.validate() && !this.emailError.length && !this.slugError.length && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this promoter?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              await PromoterService.update(this.data);
              this.$next({ name: 'admin.promoters' });
            } else {
              await PromoterService.save(this.data);
              this.$next({ name: 'admin.promoters' });
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.promoters' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await PromoterService.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Promoter Name already exists'];
          }
        } else {
          this.data.slug = this.$slugify(this.data.name);
          this.key++;
        }

      }
    },
    async onChangeSlug() {
      this.slugError = [];
      if (this.data.slug) {
        const response = await PromoterService.checkDuplicateSlug(this.data.slug);
        if (response) {
          if (response._id != this.data._id) {
            this.slugError = ['Promoter Slug already exists'];
          }
        }
      }
    },
    async onChangeEmail() {
      this.emailError = [];
      if (this.data && this.data.user && this.data.user.email) {
        const response = await AuthService.checkEmail({ email: this.data.user.email });
        if (response.isEmailDublicate) {
          this.emailError = ['Email already exists'];
        }
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
