<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Performer</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="Name"
              label="Name"
              clearable
              v-model="data.name"
              :rules="[v => !!v || 'Name is required', v => (v && v.length < 50) || 'Name must less than 50 character']"
              id="Name"
              :error-messages="nameError"
              @input="nameError = []"
              @change="onChangeName()"
              placeholder="Performer Name"
            />
          </v-col>
          <!--v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="Email"
              clearable
              label="Email"
              v-model="data.email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              id="email"
              :error-messages="emailError"
              @input="emailError = []"
              @change="onChangeEmail()"
              placeholder="Performer Email"
            />
          </v-col-->
        </v-row>
        <!--v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              clearable
              name="contact"
              label="Contact"
              v-on:keypress="isNumber($event)"
              v-model="data.phone"
              :rules="[
                v => !!v || 'Contact number is required',
                v => (v && v.length == 11) || 'Contact number must 11 digit'
              ]"
              id="contact"
              placeholder="Contact of performer's manager"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="charge"
              clearable
              v-on:keypress="isNumber($event)"
              :rules="[v => !!v || 'Charges per hour is required']"
              label="Charges per hour"
              v-model="data.charge"
              id="charge"
              placeholder="Optional"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              clearable
              name="line1"
              label="Address line 1"
              v-model="data.address.line1"
              :rules="[v => !!v || 'Address line1 is required']"
              id="line1"
              placeholder="Street Address"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="line2"
              clearable
              label="Address line 2"
              v-model="data.address.line2"
              id="line2"
              placeholder="Apartment, suite etc. (optional)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              clearable
              name="town"
              label="Town/City"
              v-model="data.address.city"
              :rules="[v => !!v || 'Town/City is required']"
              id="city"
              placeholder="e.g. London"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              name="postcode"
              clearable
              label="Postal Code"
              v-model="data.address.postcode"
              :rules="[v => !!v || 'Postal Code is required']"
              id="postcode"
              placeholder="e.g. E1 6AN"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              name="country"
              label="Country"
              clearable
              v-model="data.address.country"
              :rules="[v => !!v || 'Country is required']"
              id="country"
              v-on:keypress="isLetter($event)"
              placeholder="e.g. Denmark"
            />
          </v-col>
        </v-row-->

        <v-row>
          <v-col cols="12"> Add links </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row v-for="(link, index) in data.links" :key="index">
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  dense
                  item-text="text"
                  clearable
                  item-value="value"
                  :items="linkTypes"
                  v-model="link.linkType"
                  label="Link type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  outlined
                  dense
                  v-model="link.url"
                  clearable
                  label="Link URL"
                  placeholder="Social links url"
                  :rules="[
                    v =>
                      !v ||
                      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                        v
                      ) ||
                      'Invalid URL',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1">
                <v-icon
                  v-if="index === 0 && data.links.length === 1"
                  class="cursor-pointer pt-2"
                  color="primary"
                  @click="refreshLinks(index)"
                  >mdi-refresh</v-icon
                >
                <v-icon v-if="index >= 1" class="cursor-pointer pt-2" color="primary" @click="removeLink(index)"
                  >mdi-delete-outline</v-icon
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.links.length < 3">
            <v-icon class="cursor-pointer" color="primary" @click="addLink()">mdi-plus-circle-outline</v-icon>
            <b class="cursor-pointer align-middle" style="color: #d93d34" @click="addLink()">Add link</b>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { PerformersService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Create',
      nameError: [],
      emailError: [],
      linkTypes: [
        { text: 'Youtube', value: 'youtube' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'Twitter', value: 'twitter' },
      ],
      data: {
        address: {},
        links: [{ linkType: '', url: '' }],
      },
    };
  },
  async created() {
    if (this.$route.params.id !== 'New') {
      this.text = 'Edit';
      this.getPerformerById(this.$route.params.id);
    }
  },
  methods: {
    async getPerformerById(id) {
      const performer = await PerformersService.getById(id);
      if (performer) {
        performer.address = performer.address ? performer.address : {};
        this.data = performer;
        if (this.data && this.data.links && this.data.links.length === 0) {
          this.data.links = [{ linkType: '', url: '' }];
        }
      }
    },
    async save() {
      if (this.$refs.form.validate() && !this.emailError.length && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Performer?`,
          resolve: async () => {
            this.data.links = this.data.links.filter(link => link.linkType != '' && link.url != '');
            this.loading = true;
            if (this.data._id) {
              this.data.charge = parseInt(this.data.charge + '00');
              await PerformersService.update(this.data);
              this.$next({ name: 'admin.performers' });
            } else {
              await PerformersService.save(this.data);
              this.$next({ name: 'admin.performers' });
            }
            this.loading = false;
          },
        });
      }
    },
    prepareLinksTypes() {
      this.types = [];
      for (let index = 0; index < this.linkTypes.length; index++) {
        const element = this.linkTypes[index];
        const link = this.data.links.find(l => l.linkType === element.value);
        if (!link) {
          this.types.push(element);
        }
      }
    },
    refreshLinks() {
      this.data.links = [{ linkType: '', url: '' }];
    },
    removeLink(index) {
      this.data.links.splice(index, 1);
    },
    discard() {
      this.$next({ name: 'admin.performers' });
    },
    addLink() {
      this.data.links.push({ linkType: '', url: '' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await PerformersService.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
    async onChangeEmail() {
      this.emailError = [];
      if (this.data.email) {
        const response = await PerformersService.checkDuplicateEmail(this.data.email);
        if (response) {
          if (response._id != this.data._id) {
            this.emailError = ['Email already exists'];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
.http-https-container {
  background-color: var(--v-primary-base) !important;
  color: #fff;
  min-height: 40px;
  border-radius: 5px;
}
</style>
