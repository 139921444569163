<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Survey</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              name="name"
              label="Name"
              clearable
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              placeholder="e.g. Name"
            />
          </v-col>
          <v-col cols="12" sm="9">
            <v-textarea v-model="data.description" label="Description" outlined dense clearable></v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="fromAdmin">
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="data.organiser"
              clearable
              :disabled="(data._id) ? true : false"
              :items="organisers"
              :filter="customFilter"
              @change="getEvents()"
              item-text="name"
              item-value="_id"
              label="Promoter"
              :rules="[venueRequiredRule]"
              dense
              outlined
            >
            <template v-slot:selection="data">
            <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
            </template>
            <template v-slot:item="data">
              <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
            </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="data.events"
              multiple
              :items="events"
              clearable
              :item-value="'_id'"
              label="Events"
              :rules="[v => !!v || 'Events is required']"
              dense
              outlined
            >
            <template v-slot:selection="data">
            <span>{{ data.item.title }}</span>
            </template>
            <template v-slot:item="data">
              <span>{{ data.item.title }} ({{ data.item.startsAt | date }})</span>
            </template>
          </v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-for="(question, questionIndex) in data.questions" :key="questionIndex">
          <v-col cols="12" sm="8">
            <v-text-field
              type="text"
              label="Question"
              outlined
              dense
              v-model="question.question"
              :rules="[v => !!`${v}` || 'Question is required']"
            ></v-text-field>
            <template v-for="(answer, answerIndex) in question.options">
              <v-text-field
                type="text"
                label="Add Option"
                :prepend-icon="iconTypes[question.questionType]"
                append-outer-icon="mdi-close"
                dense
                @click:append-outer="removeOption(answerIndex, questionIndex)"
                v-model="answer.answer"
                :rules="[v => !!`${v}` || 'Option is required']"
              ></v-text-field>
            </template>
            <div
              @click="addOptions(questionIndex)"
              style="cursor: pointer;"
              v-if="question.questionType && question.questionType !== 'textbox'"
            >
              <v-icon>{{ iconTypes[question.questionType] }}</v-icon
              ><span class="px-2">Add Option</span>
            </div>
            <div class="py-0 d-flex mt-4">
              <v-switch
                v-model="question.isRequired"
                style="margin-top: 4px !important;"
              ></v-switch>
              <span class="mt-2">Required</span>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              outlined
              dense
              clearable
              :items="questionTypes"
              v-model="question.questionType"
              label="Question type"
              @change="changeQuestionType(questionIndex)"
              :rules="[v => !!`${v}` || 'Question type is required']"
            >
              <template v-slot:item="data">
                <span
                  ><v-icon>{{ data.item.icon }}</v-icon> {{ data.item.text }}</span
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn color="primary" icon @click="removeQuestion(questionIndex)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-btn color="primary" text @click="addQuestions(index)" class="px-0 pt-4">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          Add questions
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment';
import { SurveyService, PromoterEventService, PromoterService, VenueService } from '@services';
export default {
  props: {
    fromAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add New',
      questionTypes: [
        { text: 'Multiple Choices', value: 'radio', icon: 'mdi-circle-outline' },
        { text: 'Checkboxes', value: 'checkbox', icon: 'mdi-square-outline' },
        { text: 'Dropdown', value: 'select', icon: 'mdi-chevron-down' },
        { text: 'Textbox', value: 'textbox', icon: 'mdi-text' },
      ],
      iconTypes: {
        radio: 'mdi-circle-outline',
        checkbox: 'mdi-square-outline',
        select: 'mdi-chevron-down',
        textbox: 'mdi-text',
      },
      data: {
        events: [],
        name: '',
        description: '',
        organiser: '',
        venue: '',
        questions: [],
      },
      events: [],
      organisers: [],
      venues: [],
    };
  },
  created: async function() {
    if (this.fromAdmin) {
      await this.getPromoters()
      this.venues = await VenueService.getAll({ status: 'active', limit: 'max'});
    } else {
      this.data.organiser = this.$user.organiser;
      this.getEvents();
    }
    if (this.$route.params.id) {
      this.text = 'Edit';
      await this.getSurveyById(this.$route.params.id);
      this.getEvents();
    } else {
      this.addQuestions()
    }
  },
  computed: {
    venueRequiredRule() {
      return (
        !(this.data.organiser && this.data.venue) ||
        'You can not select promoter or venue together'
      );
    },
    promoterRequiredRule() {
      return !(!this.data.organiser && !this.data.venue) || 'Promoter is required';
    },
  },
  methods: {
    async getSurveyById(id) {
      this.data = await SurveyService.getById(id);
    },
    async getPromoters() {
      const organiserData = await PromoterService.get({ limit: 'all' });
      this.organisers = organiserData.organisers;
    },  
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    addQuestions() {
      this.data.questions = this.data.questions || [];
      this.data.questions.push({});
      this.$forceUpdate();
    },
    async getEvents() {
      if (this.data.organiser) {
        const eventData = await PromoterEventService.getAll({ organiser: this.data.organiser, isUpcomig: true });
        this.events = eventData;
      }
    },
    addOptions(questionIndex) {
      this.data.questions[questionIndex].options =
      this.data.questions[questionIndex].options || [];
      this.data.questions[questionIndex].options.push({ answer: '' });
      this.$forceUpdate();
    },
    removeOption(answerIndex, questionIndex) {
      this.data.questions[questionIndex].options.splice(answerIndex, 1);
      this.$forceUpdate();
    },

    changeQuestionType(questionIndex) {
      this.data.questions[questionIndex].options = [];
      if (this.data.questions[questionIndex].questionType !== 'textbox') {
        this.data.questions[questionIndex].options.push({ answer: '' });
      }
      this.$forceUpdate();
    },

    removeQuestion(index) {
      this.data.questions.splice(index, 1);
      this.$forceUpdate();
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this survey?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              const data = await SurveyService.update(this.data, this.data._id);
              if (data) {
                this.discard()
              }
            } else {
              const data = await SurveyService.save(this.data);
              if (data) {
                this.discard()
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      if (this.fromAdmin) {
        this.$next({ name: 'admin.survey' });
      } else {
        this.$next({ name: 'promoter.survey' });
      }
    }
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
