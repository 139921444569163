<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} User</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="firstName"
              label="First Name"
              clearable
              v-model="data.firstName"
              :rules="[
                v => !!v || 'First Name Code is required',
                v => (v && v.length < 20) || 'First name must less than 20 character',
              ]"
              id="firstName"
              placeholder="e.g. First Name of User"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="lastName"
              label="Last Name"
              clearable
              v-model="data.lastName"
              :rules="[
                v => !!v || 'Last Name is required',
                v => (v && v.length < 20) || 'Last name must less than 20 character',
              ]"
              id="lastName"
              placeholder="e.g. Last Name of User"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" :sm="(!isEmailDisabled) ? '6' : '12'">
            <v-text-field
              outlined
              dense
              :disabled="isEmailDisabled"
              type="email"
              name="email"
              label="Email"
              clearable
              v-model="data.email"
              :error-messages="emailError"
              @input="emailError = []"
              @change="onChangeEmail()"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              id="email"
              placeholder="e.g. Email of User"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="!isEmailDisabled">
            <v-text-field
              outlined
              dense
              type="email"
              name="email"
              clearable
              label="Confirm Email"
              v-model="data.confirmEmail"
              :rules="[v => !!v || 'Confirm Email is required', emailMatchRule]"
              id="confirmEmail"
              placeholder="e.g. Confirm Email"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-select
              v-model="data.role"
              :disabled="isEmailDisabled"
              clearable
              :items="roles"
              :item-text="'label'"
              :item-value="'value'"
              label="Role"
              :rules="[v => !!v || 'Role is required']"
              dense
              outlined
            ></v-select>
          </v-col>
         
        </v-row>
        <div v-if="data.role === 'venue'">
          <v-row>
            <v-col cols="9" sm="10">
              <v-autocomplete
                v-model="data.venues"
                :items="venues"
                item-text="name"
                item-value="_id"
                label="Venue"
                clearable
                dense
                outlined
                multiple
              ></v-autocomplete>
            </v-col>
             <v-col cols="3" sm="2">
                <v-btn color="primary" block @click="dialog.addVenue = true">
                  <v-icon class="hidden-md-and-up">mdi-plus</v-icon>
                  <span class="hidden-sm-and-down"> Add new</span>
                </v-btn>
              </v-col>
          </v-row>
        </div>
        <div v-if="data.role === 'promoter' && !data._id">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                type="text"
                name="organisername"
                clearable
                label="Promoter Name"
                v-model="organiser.name"
                :error-messages="nameError"
                @input="nameError = []"
                @change="onChangeName()"
                :rules="[v => !!v || 'Promoter Name is required']"
                id="organisername"
                placeholder="e.g. Promoter Name"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                :key="key"
                type="text"
                name="organiserslug"
                clearable
                label="Promoter Slug"
                v-model="organiser.slug"
                @input="slugError = []"
                @change="onChangeSlug()"
                :error-messages="slugError"
                :rules="[v => !!v || 'Promoter Slug is required']"
                id="organiserslug"
                placeholder="e.g. Promoter Slug"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                v-model="organiser.venue"
                :items="venues"
                item-text="name"
                item-value="_id"
                label="Venue"
                clearable
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-switch
                class="pa-0 ma-0"
                v-model="organiser.isStatusVetting"
                :label="`Is Event Approval Needed ?`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                class="pa-0 ma-0"
                v-model="organiser.isAbleToRefundTicket"
                :label="`Is Able to Refund Ticket ?`"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
    <AddVenue v-if="dialog.addVenue" @close="onCloseVenue" />
  </v-form>
</template>

<script>
import { UserService, PromoterService, VenueService, AuthService } from '@services';
import AddVenue from '../../../promoter/views/events/edit/dialogs/add-venue.vue';
export default {
   components: {
    AddVenue
  },
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      startdatePicker: false,
      datepicker1: false,
      enddatePicker: false,
      datepicker2: false,
      emailError: [],
      slugError: [],
      nameError: [],
      dialog: {
        addVenue: false,
      },
      password: 'Password',
      rePassword: 'Password',
      rules: {
        required: value => !!value || 'required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      show: false,
      show1: false,
      data: {
        venues: [],
      },
      organiser: {
        isAbleToRefundTicket: false,
        isStatusVetting: true,
      },
      events: [],
      venues: [],
      roles: [
        // {
        //   label: 'Admin',
        //   value: 'admin',
        // },
        {
          label: 'Promoter',
          value: 'promoter',
        },
        {
          label: 'User',
          value: 'user',
        },
        {
          label: 'Venue',
          value: 'venue',
        },
      ],
      isEmailDisabled: false,
      key: 0,
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.isEmailDisabled = true;
      this.getUserById(this.$route.params.id);
    }
    this.getVenues();
  },
  computed: {
    emailMatchRule() {
      return this.data.email === this.data.confirmEmail || 'Confirm Email does not match';
    },
  },
  methods: {
    async getUserById(id) {
      this.data = await UserService.getById(id);
    },
    async getVenues() {
      this.venues = await VenueService.getAll();
    },
    async save() {
      if (this.$refs.form.validate() && !this.emailError.length && !this.slugError.length && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this user?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              const response = await UserService.update(this.data._id, this.data);
              if (response) {
                this.$next({ name: 'admin.users' });
              }
            } else {
              let response;
              if (this.data.role === 'promoter') {
                this.organiser.user = this.data;
                this.organiser.type = 'promoter';
                response = await PromoterService.save(this.organiser);
              } else {
                response = await UserService.save(this.data);
              }
              if (response) {
                this.$next({ name: 'admin.users' });
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.users' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.organiser.name) {
        const response = await PromoterService.checkDuplicate(this.organiser.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Promoter Name already exists'];
          }
        } else {
          this.organiser.slug = this.$slugify(this.organiser.name);
          this.key++;
        }
      }
    },
    async onChangeSlug() {
      this.slugError = [];
      if (this.organiser.slug) {
        const response = await PromoterService.checkDuplicateSlug(this.organiser.slug);
        if (response) {
          if (response._id != this.data._id) {
            this.slugError = ['Promoter Slug already exists'];
          }
        }
      }
    },
    async onChangeEmail() {
      this.emailError = [];
      if (this.data.email) {
        const response = await AuthService.checkEmail({ email: this.data.email });
        if (response.isEmailDublicate) {
          this.emailError = ['Email already exists'];
        }
      }
    },
    onChangeTitle() {
      this.value.event.slug = this.$slugify(this.value.event.title);
      this.key++;
    },
    onCloseVenue(response) {
      this.dialog.addVenue = false;

      if (response) {
        this.data.venues.push(response._id);
        this.getVenues();
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
